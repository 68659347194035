import { render, staticRenderFns } from "./DashboardHuobi1InchBsc.vue?vue&type=template&id=6efb457c&"
import script from "./DashboardHuobi1InchBsc.vue?vue&type=script&lang=js&"
export * from "./DashboardHuobi1InchBsc.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports